<template>
  <div class="page-role-detail">
    <Navbar :title="`${$route.query.id ? '领域详情' : '新增领域'}`" needBack>
      <span v-if="+$route.query.type&&$route.query.id" @click="delRole">删除</span>
      <i v-else></i>
    </Navbar>
    <div class="card m-lr-40">
      <div class="group meta">
        <div
          class="avater"
          :style="{ 'border-color': info.titleColor, color: info.titleColor }"
        >
          {{ info.title.slice(0, 4) }}
        </div>

        <span class="basic-font" v-if="$route.query.id">{{ info.title }}</span>
        <van-field
          v-model="info.title"
          clearable
          placeholder="请输入领域名称"
          v-else
        />
      </div>
      <div class="group desc">
        <p>领域描述</p>
        <van-field
        :border="false"
          v-model="info.content"
          autosize
          type="textarea"
          placeholder="请输入领域的描述内容"
        :readonly="$route.query.type==0"
         
        />
        <span
        v-if="+$route.query.type"
          :style="{ color: info.content ? '#294BF9' : '#aeaeae' }"
          @click="clear"
          >清空</span
        >
      </div>
      <div class="wraper">
        <!-- <div class="group share">
          <img src="@/assets/images/icon_share.png" alt="" />
          <span>分享</span>
        </div> -->

        <!-- <van-checkbox
          v-model="checked"
          shape="square"
          icon-size=".24rem"
          checked-color="#294BF9"
          >加入自选</van-checkbox
        > -->
      </div>
    </div>
    <van-button color="#294BF9" class="m-lr-40" @click="submit" :disabled="!info.title||!info.content||isSubmit"   v-if="+$route.query.type"
    >保存</van-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      colors: ["#24BE48", "#7124BE", "#BE9C24", "#2462BE", "#BE248A"],
      info: {
        title: "",
        content:"",
        customerId: localStorage.getItem("id"),
      },
      checked: false,
      isSubmit:false
    };
  },
  created() {
    if (this.$route.query.id) {
      this.getRoleInfo();
    } else {
      this.info.titleColor =
        this.colors[Math.floor(Math.random() * this.colors.length)];
    }
  },
  methods: {
    delRole(){
      this.$dialog.confirm({
      message: '确认删除该领域?',
    }).then(async ()=>{
  const res= await  this.$api.role.delRole({id:this.$route.query.id})
  if(res.code==200){
    this.$toast('删除成功')
  }
   this.$router.back()
    }).catch(()=>{})
    },
    clear() {
      this.info.content = "";
    },
    async getRoleInfo() {
      const res = await this.$api.role.getRoleInfo({
        id: this.$route.query.id,
      });
      this.info = res.data;
    },
    async submit() {
      this.isSubmit=true
      if (this.$route.query.id) {
        await this.$api.role.updateRoleInfo(this.info);
      } else {
        await this.$api.role.addRoleInfo(this.info);
      }
      this.isSubmit=false
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep.van-field{
  .van-field__body,textarea{
  height: 100% !important;
}
} 
.page-role-detail {
  min-height: 100vh;
  background-color: white;
  .card {
    margin-top: 0.4rem;
    padding: 0.28rem 0.3rem 0.24rem;
    background: #ffffff;
    box-shadow: 0 0 0.28rem 0 rgba(101, 101, 101, 0.15);
    border-radius: 0.2rem;
    .group {
      display: flex;
      align-items: center;
    }
    .meta {
      padding-bottom: 0.28rem;
      align-items: center;
      border-bottom: 1px dashed #d1d1d1;
      .van-field {
        width: 90%;
        padding: 0;
      }
    }
    .avater {
      margin-right: 0.2rem;
      width: 0.64rem;
      height: 0.64rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.04rem 0.04rem;
      box-sizing: border-box;
      background: #ffffff;
      border-width: 1.5px;
      border-style: solid;
      line-height: .2rem;
      font-size: 0.18rem;
      font-weight: 400;
      text-align: center;
      border-radius: 50%;
    }
    .desc {
      margin: 0.2rem 0 0.3rem;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      p {
        margin-bottom: 0.2rem;
        font-size: 0.28rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .van-field {
        padding: 0.2rem .2rem .5rem;
        background-color: #f5f7fb;
        border-radius: 0.2rem;
        min-height: 2.52rem;
        

        border: 1px solid #edf3ff;
box-sizing: border-box;
      }
      span {
        position: absolute;
        bottom: 0.2rem;
        right: 0.2rem;
        font-size: 0.26rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
    .share {
      img {
        width: 0.24rem;
        height: 0.24rem;
      }
      span {
        margin-left: 0.05rem;
        font-size: 0.24rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #7e7e7e;
      }
    }
    .wraper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      ::v-deep.van-checkbox {
        font-size: 0.24rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #7e7e7e;
        .van-checkbox__label {
          margin-left: 0.06rem;
        }
        .van-checkbox__icon {
          height: auto;
        }
        .van-icon-success {
          line-height: inherit;
          font-size: 0.24rem;
        }
      }
    }
  }
  .van-button {
    width: 6.72rem;
    height: 1rem;
    background: #294bf9;
    border-radius: 0.2rem;
    font-size: 0.4rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>